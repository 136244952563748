import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import _ from 'lodash';
import fp from 'lodash/fp';

import { Menu } from '@myungsoo/base/components';
import { mediaQueries as mq } from '@myungsoo/base/styles';

import CrossfadeImages from './CrossfadeImages';

const MenuItem = ({ ...restProps }) => (
  <Menu.Item
    css={css`
      @media ${mq[0]} {
        height: auto;
        justify-content: flex-start;
        font-size: 4rem;
        padding: 1rem;
      }
    `}
    {...restProps}
  />
);

const Home = ({
  config: { DB_ROOT_PATH },
  onLoadingChange = () => {},
}) => {
  const [backgroundImages, setBackgroundImages] = React.useState([]);

  const loadBackgroundImages = React.useCallback(async () => {
    try {
      const firebase = await import('firebase/app');
      const db = firebase.firestore();
      const pageRef = db.doc(`${DB_ROOT_PATH}/pages/home`);
      const imagesRef = pageRef.collection('backgroundImages');

      const imageCollection = await imagesRef
        .orderBy('createdAt', 'desc')
        .get();

      const loadedImages = fp.flow(
        fp.map((image) => ({
          id: image.id,
          ...image.data(),
        })),
        fp.orderBy('displayOrder', 'asc'),
      )(imageCollection.docs);

      setBackgroundImages(loadedImages);
    } finally {
      onLoadingChange(false);
    }
  }, [DB_ROOT_PATH, onLoadingChange]);

  React.useEffect(() => {
    loadBackgroundImages();
  }, [loadBackgroundImages]);

  return (
    <>
      <CrossfadeImages
        css={css`
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          pointer-events: none;
        `}
        sources={_.map(backgroundImages, ({ url }) => url)}
      />
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
        `}
      >
        <MenuItem render={Link} to="/about">
          About
        </MenuItem>
        <MenuItem render={Link} to="/reel">
          Reel
        </MenuItem>
        <MenuItem render={Link} to="/news">
          News
        </MenuItem>
        <MenuItem render={Link} to="/contact">
          Contact
        </MenuItem>
      </div>
    </>
  );
};

export default Home;
