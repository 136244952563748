import React from 'react';
import { css } from '@emotion/core';

import { Header } from '@myungsoo/base/components';
import Home from '@myungsoo/base/pages/Home';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as config from '../../config';

const HomePage = () => {
  const [loading, setLoading] = React.useState(true);
  return (
    <Layout
      css={css`
        ${Header} {
          background: none;
        }
      `}
      loading={loading}
      showMenu={false}
      showFooter={false}
    >
      <Seo />
      <Home config={config} loading={loading} onLoadingChange={setLoading} />
    </Layout>
  );
};

export default HomePage;
