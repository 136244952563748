import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import _ from 'lodash';

const Image = ({ show, onLoad = () => {}, ...restProps }) => {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <img
      css={[
        css`
          opacity: 0;
          transition: opacity 1s;
        `,
        show &&
          loaded &&
          css`
            opacity: 1;
          `,
      ]}
      alt=""
      onLoad={(event) => {
        setLoaded(true);
        onLoad(event);
      }}
      {...restProps}
    />
  );
};

const CrossfadeImages = styled(({ sources = [], ...restProps }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [loadedIndicies, setLoadedIndecies] = React.useState({});

  React.useEffect(() => {
    if (!loadedIndicies[selectedIndex]) {
      return;
    }

    const timeoutId = setTimeout(() => {
      const nextIndex = selectedIndex + 1;
      if (nextIndex < _.size(sources)) {
        setSelectedIndex(nextIndex);
      } else {
        setSelectedIndex(0);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [sources, selectedIndex, loadedIndicies]);

  React.useEffect(() => {
    setLoadedIndecies({});
  }, [sources]);

  return (
    <div {...restProps}>
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
        `}
      >
        {_.map(sources, (src, index) => (
          <Image
            key={src}
            src={src}
            show={index === selectedIndex}
            css={[
              css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                object-fit: cover;
              `,
            ]}
            onLoad={() => setLoadedIndecies((prevState) => ({ ...prevState, [index]: true }))}
          />
        ))}
      </div>
    </div>
  );
})``;

export default CrossfadeImages;
